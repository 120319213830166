// utils/displayFormatters.js

export const formatLeadSource = (source) => {
  const sourceMapping = {
    'google': 'Google',
    'facebook': 'FaceBook',
    'instagram': 'Instagram',
    'youtube': 'YouTube',
    'other': 'Other Social Media',
    'preferral': 'Patient Referral',
    'sreferral': 'Staff Referral',
    'dreferral': 'Doctor Referral',
    'signage': 'Signage/Walk by',
    'flyer': 'Flyer/Postcard',
    'seminar': 'Seminar',
    'tradeshow': 'Tradeshow/Community Event',
  };

  return sourceMapping[source] || source;
};

export const formatLeadType = (type) => {
  if (!type) return '';
  
  // First replace underscores with spaces
  let formatted = type.replace(/_/g, ' ');
  
  // Handle special case for 'chatbot' -> 'Chat Bot'
  if (formatted === 'chatbot') {
    return 'Chat Bot';
  }

  if (formatted === 'phonecall') {
    return 'Phone';
  }

  // Split by spaces and capitalize each word
  return formatted
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

// Add status mapping
export const LEAD_STATUSES = [
  { key: "new", label: "New Lead"},
  { key: "contacted", label: "Contacted"},
  { key: "scheduled", label: "Scheduled"},
  { key: "qualified", label: "New Patient"},
  { key: "existing", label: "Existing Patient"},
  { key: "noshow", label: "No Show"},
  { key: "lost", label: "Not Interested"},
];

export const LEAD_TYPES = [
  { key: "contact_form", label: "Contact Form"},
  { key: "website", label: "Website"},
  { key: "phonecall", label: "Phone"},
  { key: "chatbot", label: "Chat Bot"},
];

export const LEAD_SOURCES = [
  { key: "google", label: "Google" },
  { key: "facebook", label: "FaceBook" },
  { key: "instagram", label: "Instagram" },
  { key: "youtube", label: "YouTube" },
  { key: "other", label: "Other Social Media" },
  { key: "preferral", label: "Patient Referral" },
  { key: "sreferral", label: "Staff Referral" },
  { key: "dreferral", label: "Doctor Referral" },
  { key: "signage", label: "Signage/Walk by" },
  { key: "flyer", label: "Flyer/Postcard" },
  { key: "seminar", label: "Seminar" },
  { key: "tradeshow", label: "Tradeshow/Community Event" }
];

// Helper function to get status label
export const formatLeadStatus = (status) => {
  const statusObj = LEAD_STATUSES.find(s => s.key === status?.toLowerCase());
  return statusObj?.label || status;
};

// Helper function to get status color
export const getLeadStatusColor = (status) => {
  const statusObj = LEAD_STATUSES.find(s => s.key === status?.toLowerCase());
  return statusObj?.color || "bg-gray-500";
};