import React from "react";

const NavMenu = ({ onMenuSelect, activeRoute = "/" }) => {
  const menuItems = [
    {
      label: "Leads",
      action: "/leads",
    },
  ];

  const getIsActive = (path) => {
    if (path === "/") {
      return activeRoute === "/"; // Exact match for home
    }
    return activeRoute.includes(path.slice(1)); // Inclusive for others
  };

  const getButtonClass = (path) => {
    const isActive = getIsActive(path)
    return `text-blue-500 hover:text-blue-700 font-bold focus:outline-none focus:text-blue-600 transition duration-300 relative ${
      isActive ? "text-sky-700" : ""
    }`;
  };

  const getLineClass = (path) => {
    const isActive = getIsActive(path)
    return `absolute bottom-0 left-0 w-full h-0.5 bg-blue-700 transform transition-transform duration-300 ${
      isActive ? "scale-x-100" : "scale-x-0 group-hover:scale-x-100"
    }`;
  };

  return (
    <nav className="w-full px-8 sm:px-16 md:px-24 lg:px-36 flex items-center">
      <ul className="flex space-x-8">
        <li key="home" className="relative group">
          <button
            onClick={() => onMenuSelect("/")}
            className={getButtonClass("/")}
            aria-haspopup="true"
            aria-expanded="false"
          >
            Home
            <span className={getLineClass("/")}></span>
          </button>
        </li>
        {menuItems.map((item) => (
          <li key={item.action} className="relative group">
            <button
              onClick={() => onMenuSelect(item.action)}
              className={getButtonClass(item.action)}
              aria-haspopup="true"
              aria-expanded="false"
            >
              {item.label}
              <span className={getLineClass(item.action)}></span>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavMenu;
