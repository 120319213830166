import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getLeads } from "../services/api.js";
import { formatDate } from "../utils";
import { LEAD_STATUSES, LEAD_TYPES, formatLeadStatus, formatLeadSource } from '../utils/displayFormatters';
import { formatLeadType } from '../utils/displayFormatters';

const LeadList = ({ authToken }) => {
  const [leads, setLeads] = useState([]);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [sortOrder, setSortOrder] = useState("-created_at");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [fields] = useState([
    { key: "fullName", label: "Full Name", sortKey: "last_name" },
    { key: "status", label: "Status", sortKey: "status" },
    { key: "type", label: "Type", sortKey: "type" },
    { key: "source", label: "Source", sortKey: "source" },
    { key: "updated_at", label: "Last Update", sortKey: "updated_at" },
  ]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await getLeads(
          selectedStatus,
          selectedType,
          selectedSource,
          sortOrder,
          currentPage,
          pageSize
        );
        setLeads(response.results);
        setTotalPages(Math.ceil(response.count / pageSize));

        // Extract unique sources from leads
        const uniqueSources = [...new Set(response.results.map(lead => lead.source))]
          .map(source => ({
            key: source,
            label: source
          }));
        setSources(uniqueSources);
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };
    fetchLeads();
  }, [selectedStatus, selectedType, selectedSource, sortOrder, currentPage, pageSize]);

  const handleSort = (sortKey) => {
    if (!sortKey) return;
    
    setSortOrder(current => {
      if (current === sortKey) return `-${sortKey}`;
      if (current === `-${sortKey}`) return sortKey;
      return sortKey;
    });
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "new":
        return "bg-sky-100 text-black";
      case "contacted":
        return "bg-yellow-100 text-black";
      case "scheduled":
        return "bg-green-200 text-black";
      case "qualified":
        return "bg-green-100 text-black";
      case "converted":
        return "bg-purple-100 text-black";
      case "lost":
        return "bg-red-100 text-black";
      case "noshow":
        return "bg-red-100 text-black";
      default:
        return "bg-gray-100 text-black";
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Active Leads</h1>
        <Link
          to="/leads/create"
          className="bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded transition duration-300"
        >
          Create New Lead
        </Link>
      </div>

      <div className="flex space-x-2">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search..."
            className="pl-8 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <svg
            className="absolute left-2 top-2.5 h-5 w-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setCurrentPage(1);
          }}
          className="border border-gray-300 rounded-md px-4 py-2"
        >
          <option value="10">10 per page</option>
          <option value="25">25 per page</option>
          <option value="50">50 per page</option>
        </select>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Statuses</option>
          {LEAD_STATUSES.map((status) => (
            <option key={status.key} value={status.key}>
              {status.label}
            </option>
          ))}
        </select>
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Type</option>
          {LEAD_TYPES.map((source) => (
            <option key={source.key} value={source.key}>{source.label}</option>
          ))}
        </select>
        <select
          value={selectedSource}
          onChange={(e) => setSelectedSource(e.target.value)}
          className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Source</option>
          {sources.map((source) => (
            <option key={source.key} value={source.key}>{source.label}</option>
          ))}
        </select>
      </div>

      <div className="bg-white shadow-sm border border-gray-200 rounded-md overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {fields.map((field) => (
                <th
                  key={field.key}
                  onClick={() => field.sortKey && handleSort(field.sortKey)}
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                    field.sortKey ? 'cursor-pointer hover:bg-gray-100 select-none' : ''
                  }`}
                >
                  <div className="flex items-center space-x-1">
                    <span>{field.label}</span>
                    {field.sortKey && (
                      <span className="inline-block w-4">
                        {sortOrder === field.sortKey && "↑"}
                        {sortOrder === `-${field.sortKey}` && "↓"}
                      </span>
                    )}
                  </div>
                </th>
              ))}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {leads.map((lead) => (
              <tr key={lead.id} className="even:bg-gray-50 hover:bg-sky-50 transition-colors duration-150">
                {fields.map((field) => (
                  <td
                    key={`${lead.id}-${field.key}`}
                    className="px-6 py-4 whitespace-normal text-gray-600"
                  >
                    {field.key === "fullName" ? (
                      <Link
                        to={`/leads/${lead.id}`}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        {`${lead.first_name} ${lead.last_name}`}
                      </Link>
                    ) : field.key === "status" ? (
                      <span
                        className={`px-2 inline-flex text-sm leading-5 font-semibold rounded-full ${getStatusColor(
                          lead[field.key]
                        )}`}
                      >
                        {lead.status_display}
                      </span>
                    ) : field.key === "type" ? (
                      <div className="text-sm text-gray-500">
                        {formatLeadType(lead[field.key])}
                      </div>
                    ) : field.key === "source" ? (
                      <div className="text-sm text-gray-500">
                        {formatLeadSource(lead[field.key])}
                      </div>
                    ) : field.key === "created_at" || field.key === "updated_at" ? (
                      <div className="text-sm text-gray-500">
                        {formatDate(lead[field.key], "long")}
                      </div>
                    ) : (
                      <div className="text-sm text-gray-500">
                        {lead[field.key]}
                      </div>
                    )}
                  </td>
                ))}
                <td className="px-6 py-4 whitespace-normal text-sm font-medium">
                  <Link
                    to={`/leads/${lead.id}`}
                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LeadList;